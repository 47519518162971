import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    padding: "80px 0 30px 0",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    "& .footerAbout": {
      display: "flex",
      justifyContent: "space-evenly",
      marginTop:"16px",
      "& .MuiTypography-body2":{
        lineHeight:"35px"
      },
      "& .MuiTypography-body3":{
        fontSize:"16px"
      }
    },
    "& .footer": {
      width: "72%",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",

      "& .footer_content": {
        // width: "60%",

        "& .footer_data": {
          display: "flex",
          flexDirection: "column",
          gap: "32px",

          "& .button": {
            backgroundColor: "#192654",
            height: "36px",
          },
        },
      },
    },

    "& .copyright": {
      widht: "68%",
      display: "flex",
      justifyContent: "space-around",

      "& .icons": {
        display: "flex",
        gap: "16px",
        zIndex: 2,

        "& img": {
          cursor: "pointer",
          scale: 0.9,
        },
      },
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <Box className={classes.footerSection}>
      <Box className="footer">
        <Grid container spacing={4} className="footer_content">
          <Grid item xs={12} md={4} className="footer_data">
            <img src="/assets/maxtrin.png" alt="logo" width="112px" />
            <Typography variant="body1">
              Write a short paragraph that explains what your company helps
              customers with.
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Join our newsletter"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      className="button"
                    >
                      Sign Up
                    </Button>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
          <Grid item xs={0} md={4}></Grid>
          <Grid item xs={12 } md={4}>
            <Box>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Company
              </Typography>
              <Box className="footerAbout">
                <Box>
                  <Typography variant="body2"  style={{cursor:"pointer"}} onClick={() => scrollToSection('about')}>
                    About us
                  </Typography>
                  <Typography variant="body2"   style={{cursor:"pointer"}} onClick={() => scrollToSection('purpose')}>
                    Our Purpose
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2"  style={{cursor:"pointer"}} onClick={() => scrollToSection('services')}>
                    Services
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={6} md={2} className="footer_data">
            <Typography variant="h6">Legal</Typography>
            <Typography variant="body2">Terms of service</Typography>
            <Typography variant="body2">Privacy policy</Typography>
            <Typography variant="body2">Cookie policy</Typography>
          </Grid> */}
        </Grid>
      </Box>

      <Divider sx={{ borderColor: "#2E3039" }} />
      <Box className="copyright">
        <Typography variant="body3" classes="body3">
          Company name © 2024 - 123 Main Street Anytown, USA 98765
        </Typography>
        {/* <Box className="icons">
          <img src="/assets/fb.png" alt="icons" />
          <img src="/assets/x.png" alt="icons" />
          <img src="/assets/insta.png" alt="icons" />
          <img src="/assets/yt.png" alt="icons" />
          <img src="/assets/discord.png" alt="icons" />
          <img src="/assets/tel.png" alt="icons" />
        </Box> */}
      </Box>
    </Box>
  );
};

export default Footer;
