import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
// import Partners from "src/views/pages/static/Partners/Partners";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },

  {
    exact: true,
    path: "/login",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/login/Login")),
  },

  {
    exact: true,
    path: "/login-password",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/login/Loginpassword")),
  },

  {
    exact: true,
    path: "/register",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/signup/Signup")),
  },

  {
    exact: true,
    // guard:true,
    path: "/reset-password",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/resetpassV2/Resetpassword")
    ),
  },

  {
    exact: true,
    path: "/register-password",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/regipassword/regipassword")
    ),
  },

  {
    exact: true,
    // guard:true,
    path: "/forgot",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/forgot/forgot")),
  },



  {
    exact: true,
    // guard:true,
    path: "/verify-otp",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/otpverification/otpverify")
    ),
  },

  {
    exact: true,
    path: "/signup-verified",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/verification/Signupverify")
    ),
  },

  // {
  //   exact: true,
  //   path: "/login-verified",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Auth/verification/Loginverify")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/blog",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Blog/Blog")),
  // },

  // {
  //   exact: true,
  //   path: "/faq",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/FAQ/Faq")),
  // },
  // {
  //   exact: true,
  //   path: "/policy",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Policy/Index.js")),
  // },
  {
    exact: true,
    path: "/CareerOptions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CareerOptions")),
  },

  // {
  //   exact: true,
  //   path: "/tax-fee",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Taxes/Taxes")),
  // },

  // {
  //   exact: true,
  //   path: "/community",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Community/Index")),
  // },



  {
    exact: true,
    path: "/skill-bridge",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/SkillBridge/Index")
    ),
  },


  

  {
    exact: true,
    path: "/search-data",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/DefaultSearch/DefaultSearch")
    ),
  },

  // {
  //   exact: true,
  //   path: "/view-skill-category",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Skillmarketplace/ViewSkillCategory")
  //   ),
  // },
  {
    exact: true,
    path: "/scanCode",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Blockchain")),
  },
  {
    exact: true,
    path: "/endorsement",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Endorsement")),
  },
  {
    exact: true,
    path: "/view-Qr-code",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/Blockchain")),
  },





  // {
  //   exact: true,
  //   path: "/course-details",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Home/LandingPage/CreateCourse")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/course-details/:courseName",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Home/LandingPage/CreateCourse")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/lite-paper",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/LightPaper/LightPaper")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/platform-specifics",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/LightPaper/PlatformSpecifics")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/data-privacy",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/LightPaper/DataPrivacy")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/white-paper",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/LightPaper/WhitePaper")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/privacy-policy",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/PrivacyPolicy")),
  // },

  // {
  //   exact: true,
  //   path: "/terms-conditions",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/TermsAndCondition")),
  // },

  // {
  //   exact: true,
  //   path: "/media",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Media/Media")),
  // },
  // {
  //   exact: true,
  //   path: "/careers",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Career/Career")),
  // },
  // {
  //   exact: true,
  //   path: "/contact-us",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/ContactUs")),
  // },

  // {
  //   exact: true,
  //   path: "/gyan-blog",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Blog/BlogSecond")),
  // },

  {
    exact: true,
    path: "/approval",
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Badges/Approval")),
  },



  // {
  //   exact: true,
  //   path: "/sub-category",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home/Courses/SubCategory")),
  // },
  {
    exact: true,
    path: "/courses/:categoryName",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Courses/SubCategory")),
  },
  
  {
    exact: true,
    path: "/partner-with-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/IAICOE/PartnerWithUS")),
  },
  // {
  //   exact: true,
  //   path: "/category",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Skillmarketplace/ViewCategory")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/category/:categoriesName",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Skillmarketplace/ViewCategory")
  //   ),
  // },

  // {
  //   exact:true,
  //   path:'/coes',
  //   layout:HomeLayout,
  //   component: lazy(()=> import("src/views/pages/Home/IAICOE/Coes")),
  //  },
  {
    exact: true,
    path: "/about-coe-program",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/IAICOE/AboutIAICOES")),
  },

  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Partners/Partners")),
    
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/vindhya-institute-technology-science",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/VITSDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/:collegeName",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/CollegeData")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/buddha-institute-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/BITGDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/arya-college-engineering-it",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/AryaDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/bsa-college-engineering-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/BSADeatails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/brcm-college-engineering-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/BRCMDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/greater-noida-institute-of-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/GNITDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/kashi-institute-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/KITDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/shambhunath-institute-engineering-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/SIETDetails")
  //   ),
  // },
 
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/bharat-institute-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/BITMDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/dy-patil-vidyapeeth",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/DYPVDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/global-institute-technology",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Partners/GITDetails")),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/g-h-raisoni-college-engineering-management",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/GHRCEMDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/haridev-joshi-university-journalism-mass-communication",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/HJUJMCDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/institute-technology-management",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Partners/ITMDetails")),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/maharana-pratap-group-institutions",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/MPGIDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/marathwada-mitra-mandals-institute-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/MMMITPDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/maulana-azad-university",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/MAUJDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/prasad-institute-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/PITJDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/poornima-university",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Partners/PUJDetails")),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/rajarshi-school-management-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/RSMTVDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/roorkee-institute-technology",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/RITRDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/shivnagar-vidya-prasarak-mandal",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/SVPMPDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/school-management-sciences",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/SMSLDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/tulas-institute",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Partners/TIDetails")),
  // },
  // {
  //   exact: true,
  //   path: "/Existing-COE-Partners/zeal-college-engineering-research",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/static/Partners/ZCERDetails")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/all-categories",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Skillmarketplace/Viewallcategory")
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/ranking",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Ranking")),
  // },
  // {
  //   exact: true,
  //   path: "/activity",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Activity")),
  // },
  {
    exact: true,
    path: "/skill-drops",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Drops/CreateDrops")),
  },


  // {
  //   exact: true,
  //   path: "/catalouge",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Catalogue")),
  // },

  // {
  //   exact: true,
  //   path: "/resource-center",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/ResourceCenter")),
  // },



  // {
  //   exact: true,
  //   path: "/about-us",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/About/About")),
  // },

  // {
  //   exact: true,
  //   path: "/roadmap",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/static/Roadmap")),
  // },

  {
    exact: true,
    path: "/payment-history",
    component: lazy(() => import("src/views/pages/Courses/SuccessScreen")),
  },
  {
    exact: true,
    path: "/invoice",
    component: lazy(() => import("src/views/pages/Courses/Invoice")),
  },
  {
    exact: true,
    path: "/payment-bundle-history",
    component: lazy(() => import("src/views/pages/Courses/SuccessScreen")),
  },
  // {
  //   exact: true,
  //   path: "/media1",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent1")),
  // },
  // {
  //   exact: true,
  //   path: "/media2",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent2")),
  // },
  // {
  //   exact: true,
  //   path: "/media3",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent3")),
  // },
  // {
  //   exact: true,
  //   path: "/media4",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent4")),
  // },
  // {
  //   exact: true,
  //   path: "/media5",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent5")),
  // },
  // {
  //   exact: true,
  //   path: "/media6",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent6")),
  // },
  // {
  //   exact: true,
  //   path: "/media7",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent7")),
  // },
  // {
  //   exact: true,
  //   path: "/media8",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent8")),
  // },
  // {
  //   exact: true,
  //   path: "/media9",
  //   layout: HomeLayout,

  //   component: lazy(() => import("src/views/pages/static/Media/MediaContent9")),
  // },
  {
    component: () => <Redirect to="/404" />,
  },
];
