import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Menu,
  Paper,
  Dialog,
  Typography,
  TextField,
  Select,
  FormControl,
  Grid,
  Avatar,
  Popover,
  Divider,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-hot-toast";
import { Link, useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "src/context/Auth";
import { apiConfig } from "src/apiconfig/ApiConfig";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CiSearch } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

const useStyles = makeStyles((theme) => ({
  transparentTextfield: {
    "& .MuiOutlinedInput-root": {
      background: "rgba(255,255,255,0.2)",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiOutlinedInput-input": {
      "&::placeholder": {
        color: "rgba(177, 177, 177, 1)",
      },
    },

    "& .MuiInputBase-input": {
      color: "rgba(255,255,255,0.8)",
    },
  },
  PopOverClass: {
    "& .MuiPopover-paper": {
      padding: "20px 0 18px 0 ",
      width: "100%",
      maxWidth: "220px",
      position: "absolute",
    },
  },
  profileMenuItem: {
    backgroundColor: "white",
    gap: "10px",
    alignItems: "center",
    padding: "0",

    "& .btnProfile": {
      whiteSpace: "pre",
      border: "none",
      "& h5": {
        fontSize: "16px",
        color: "#262626",
        display: "flex",
        alignItems: "start",
      },
    },
  },
  profileAccount: {
    backgroundColor: "white",
    gap: "10px",
    alignItems: "center",
    marginTop: "10px",
    "& .myAccTypo": {
      fontSize: "16px",
      color: "#262626",
    },
  },
  suggestions: {
    color: "#262626",
    position: "absolute",
    backgroundColor: "#fff",
    top: "44px",
    borderRadius: "10px",
    background: "#fff",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    width: "100%",
    maxWidth: "400px",
    "& .suggestionslist": {
      cursor: "pointer",
      paddingLeft: "20px",
      "& li": {
        marginBottom: "6px",
      },
    },
  },
}));

const HeaderSearch = () => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const globalSearch = auth?.globalSearch;
  const setGlobalSearch = auth?.setGlobalSearch;
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const inputRef = useRef(null);
  const previousPath = useRef(null);
  const [userInput, setUserInput] = useState(true);

  const skillMarketPlaceRoute =
    history.location.pathname === "/skill-marketplace" ||
    history.location.pathname === "/skill-bridge" ||
    history.location.pathname === "/skill-drops";

  useEffect(() => {
    if (
      !(
        location.pathname === "/search-data" ||
        location.pathname.startsWith("/search-data/")
      )
    ) {
      // Clear global search when leaving "/search-data"
      clearSearch();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      !(
        history.location.pathname === "/search-data" ||
        history.location.pathname.startsWith("/search-data/")
      )
    ) {
      previousPath.current = history.location.pathname;
    }
  }, [history.location.pathname]);

  const handleSearch = async (value) => {
    if (
      value !== globalSearch &&
      userInput &&
      !["/register", "/verify-otp", "/login"].includes(history.location.pathname)
    ) {
      setGlobalSearch(value);

      if (value.trim() !== "") {
        try {
          const params = { name: value };
          const res = await axios.get(apiConfig.getAllCategory, { params });
          if (res?.data?.responseCode === 200) {
            setSuggestions(res?.data?.result?.docs);
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
        history.push("/search-data");
      } else {
        clearSearch();
      }
    }
  };

  const clearSearch = () => {
    setGlobalSearch("");
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUserInput(true);
    const cleanedValue = value.replace(/ {2,}/g, ' ').trimStart();
    handleSearch(cleanedValue);
  };

  return (
    <FormControl fullWidth style={{ maxWidth: "400px" }}>
      <TextField
        variant="outlined"
        fullWidth
        className={skillMarketPlaceRoute ? classes.transparentTextfield : ""}
        placeholder="Search"
        autoComplete="off"
        value={globalSearch}
        inputRef={inputRef}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CiSearch style={{ fontSize: "large" }} />
            </InputAdornment>
          ),
          endAdornment: globalSearch && (
            <InputAdornment position="end">
              <RxCross2
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={() => {
                  clearSearch();
                  history.push("/");
                }}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: 70,
        }}
      />
    </FormControl>
  );
};

export default HeaderSearch;
