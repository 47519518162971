import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { RiMenuLine } from "react-icons/ri";

const pages = ["Home", "About", "Services"];

const TopBarNew = () => {
  const [openNav, setOpenNav] = React.useState(false);

  const handleNavMenu = () => {
    setOpenNav((prev) => !prev);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          margin: "0 auto",
          width: "72%",
          justifyContent: "space-between",
          padding: "16px",
          borderRadius: "16px",
          background: "#FFFFFF",
          boxShadow: "0px 4px 6px -1px #12121714",
          position: "sticky",
          top: "20px",
          zIndex: 10,
        }}
      >
        <img src="/assets/maxtrin.png" alt="logo" />

        <Box sx={{ display: { xs: "none", md: "flex" }, gap: "28px" }}>
        <Button
        variant="text"
        onClick={() => {
          const id = "home";
          const element = document.getElementById(id);
          if (element) {
            const offset = 80; // Offset from the top
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }}
      >Home</Button>
          <Button
        variant="text"
        onClick={() => {
          const id = "about";
          const element = document.getElementById(id);
          if (element) {
            const offset = 80; // Offset from the top
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }}
      >
        About
      </Button>
      <Button
        variant="text"
        onClick={() => {
          const id = "services";
          const element = document.getElementById(id);
          if (element) {
            const offset = 100; // Offset from the top
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }}
      >
        Services
      </Button>

  
        </Box>

        <Box sx={{ display: { sm: "flex", md: "none", flexGrow: 1 } }}>
          <IconButton
            size="large"
            aria-label="navigation menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleNavMenu}
            color="inherit"
          >
            <RiMenuLine />
          </IconButton>
          <Drawer
            anchor="right"
            open={openNav}
            onClose={handleNavMenu}
            sx={{ "& .MuiDrawer-paper": { width: 240 } }}
          >
            <List style={{ padding: "34px" }}>
              <IconButton size="large" onClick={handleNavMenu} color="inherit">
                <img src="/assets/maxtrin.png" alt="logo" width="112px" />
              </IconButton>
              {pages.map((text) => (
                <ListItem button key={text} onClick={() => {scrollToSection(text.toLowerCase()); handleNavMenu();}}>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default TopBarNew;
