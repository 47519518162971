import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#FAFAFA",
        padding: "20px 0"
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "transparent",
      },
      head: {
        background: "#F4F7FF",
      },
    },
    MuiTableRow: {
      root: {},
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        background: "#FFF",
        borderRadius: "5px",
        height: "50px",
        "& .Mui-focused": {
          color: "#262626",
          border: "1px solid #E7E7E7",
        },
      },

      input: {
        padding:"15.5px 14px",
        
        "&::placeholder": {
          // color: "#636880",
          // color:"#8a8c95",
          fontFamily: "Roboto Condensed",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        },
      },
      notchedOutline: {
        borderColor: "#E7E7E7",
      },
      colorSecondary: {},
    },

    MuiSelect: {
      select: {
        backgroundColor: "#FFF",
        borderRadius: "5px",
        border: "1px solid #E7E7E7",
        // color: "#b8bac1",
        color:"#8a8c95",
        // fontSize:"18px",
        fontSize:"inherit",
        "@media (max-width:600px)":{
          fontSize:"12px",
        },
        "&:focus": {
          backgroundColor: "#F2F2F2",
        },
      },
    },
    
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        background: "#fff",
        borderRadius: "10px",
        padding: "26px 20px",
        boxShadow: "none",
      },
      elevation2: {
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 68.15%)",
        border: "none",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "none",
      },
    },

    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },

    MuiMenuItem: { root: { paddingLeft: "20px" } },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#4D164F" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "scroll",
      },
      paperWidthSm: {
        maxWidth: "900px",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#262626",
        // color:"#8a8c95",
        
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiAutocomplete: {
      option: {
        fontFamily: "Poppins !important",
        fontSize: "12px !important",
        fontWeight: "400 !important",
        lineHeight: "18px !important",
        letterSpacing: "0px !important",
        textAlign: "left !important",
      },
    },

    
    MuiButton: {
      containedSecondary: {
        color: "#465AA2",
        height: "40px",
        fontSize: "16px",
        backgroundColor: "#E6EDFF",
        border: "none",
        fontWeight: 600,
        lineHeight: "normal",
        fontFamily: "Outfit",
        borderRadius: "50px",
        boxShadow:'none',
        "&:hover": {
          backgroundColor: "#E6EDFF", 
          boxShadow:'none',
        },
        "@media (max-width :480px)":{
          fontSize:"12px"
        }
      },

      text: {
        color: "#4C567A",
        font: "16px Outfit",
        fontWeight: "600",
      },

      containedPrimary: {
        color: "#fff",
        boxShadow:"none",
        height: "58px",
        fontSize: "18px",
        backgroundColor: "#2E63FF",
        border: "none",
        fontWeight: 600,
        lineHeight: "normal",
        fontFamily: "Outfit",
        border:"1px solid transparent",
        padding: "0 20px",
        borderRadius: "8px",
        "&:hover": {
          color: "#fff",
          border: "1px solid #2E63FF",
          backgroundColor: "#0856CC",
        },
        "@media (max-width :480px)":{
          fontSize:"12px"
        }
      },

      // contained: {
      //   color: "#fff",
      //   height: "40px",
      //   padding: "10px 39px",
      //   fontSize: "14px",
      //   background: "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
      //   border: "1px solid #4D164F",
      //   fontWeight: "500",
      //   lineHeight: "21px",
      //   fontFamily: "Outfit",
      //   borderRadius: "50px",
      //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
      //   backgroundColor:
      //     "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
      //   "&:hover": {
      //     color: "#fff",
      //     border: "1px solid #E1E1E1",
      //     backgroundColor: "#F2F2F2",
      //   },
      // },

      outlinedPrimary: {
        color: "#080515",
        border: "1px solid #4D164F",
        padding: "5px 25px",
        fontWeight: "500",
        borderRadius: "50px",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "#4D164F",
          border: "1px solid #4D164F",
          color: "#fff",
        },
      },

      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "Roboto Condensed",
  },

  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },

    background: {
      default: "#fff",
      dark: "#f3f7f9",
      secondary: '#F4F4F4',
      paper: colors.common.white,
    },

    primary: {
      main: "#0856CC",
      dark: "#080515",
      light: "rgba(0, 0, 0, 0.60)",
    },
    secondary: {
      main: "#262626",
    },

    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },

    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },

    error: {
      main: "#DC0404",
      dark: "#DC0404",
      light: "#DC0404",
    },

    text: {
      primary: "#636880",
      secondary: "rgba(8, 5, 21, 0.60)",
    },
    common: {
      black: "#222222",
    },

  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
