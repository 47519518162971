import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Menu,
  Paper,
  Dialog,
  Typography,
  TextField,
  Select,
  FormControl,
  Grid,
  Hidden,
  SvgIcon,
  Avatar,
  Popover,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "src/component/Logo";
import { NavLink } from "react-router-dom";
import { UserContext } from "src/context/User";
import { GrCart } from "react-icons/gr";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CiSearch } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import Checkbox from "@material-ui/core/Checkbox";
import { KeyboardArrowDown } from "@material-ui/icons";
import clsx from "clsx";
import { menuProps } from "src/utils";
import { IoNotificationsOutline } from "react-icons/io5";
import { AuthContext } from "src/context/Auth";
import { apiConfig } from "src/apiconfig/ApiConfig";
import { toast } from "react-hot-toast";
import axios from "axios";
import "../../HomeLayout/topbar.css";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommonPopup from "src/views/pages/Dashboard/Watchlist/CommonPopup";
import Header from "../../../component/HeaderSearch";

const headersData = [
  {
    label: "Blockchain",
    href: "/about-coe-program",
    img: "/images/blockchain.png",
  },
  {
    label: "About Us",
    href: "/about-us",
    img: "/images/Shape.png",
  },
  {
    label: "Resource Center",
    href: "/resource-center",
    img: "/images/rccenter.png",
  },
  {
    label: "Catlog",
    href: "/catalouge",
    img: "/images/catlog.png",
  },
  {
    label: "Media",
    href: "/media",
    img: "/images/media.png",
  },
  {
    label: "Lite Paper",
    href: "/lite-paper",
    img: "/images/litepaper.png",
  },
  {
    label: "FAQ",
    href: "/faq",
    img: "/images/faq.png",
  },
];

// const dropsData = [
//   {
//     label: "Featured",
//     href: "/about-coe-program",
//     img: "/images/featured.png",
//   },
//   {
//     label: "Partners",
//     href: "/doctors-page",
//     img: "/images/partner.png",
//   },
// ];

const AICEO = [
  {
    label: "Partner with us",
    href: "/partner-with-us",
    img: "/images/partnerUS.png",
  },
  {
    label: "Our Existing Partners",
    href: "/Existing-COE-Partners",
    img: "/images/existingPartner.png",
  },
];

const statsData = [
  {
    label: "Ranking",
    href: "/ranking",
    img: "/images/rank.png",
  },
  {
    label: "Activity",
    href: "/activity",
    img: "/images/activity.png",
  },
];

const langData = [
  {
    label: "English",
    href: "/about-coe-program",
  },
  {
    label: "Hindi",
    href: "/doctors-page",
  },
  {
    label: "Bengali",
    href: "/about-coe-program",
  },
  {
    label: "Urdu",
    href: "/doctors-page",
  },
  {
    label: "Punjabi",
    href: "/doctors-page",
  },

  {
    label: "Malayalam",
    href: "/doctors-page",
  },
];

const useStyles = makeStyles((theme) => ({
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  menu: {
    "& .MuiMenuItem-root": {
      display: "flex",
      alignItems: "center !important",
    },
  },
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#636880",
    padding: "0px 20px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: theme.palette.text.primary,
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  appBar: {
    "& .MuiContainer-root": {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    padding: " 5px 0px",
    "& .mainbox": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      paddingLeft: "50px",
      "@media (max-width: 1279px)": {
        paddingRight: "40px",
        paddingLeft: "0px",
      },
    },

    "& .MuiOutlinedInput-root ": {
      height: "44px",
      borderRadius: "10px",
    },
    "& .MuiSelect-icon": {
      fontSize: "x-large",
    },
    "& .MuiList-root": {
      paddingRight: "40px",
    },

    "& .MuiSelect-select ": {
      border: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },
  ecoButton: {
    borderRadius: "10px",
    width: "100%",
    minWidth: "225px",
  },
  signUpBtn: {
    background: "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
  },

  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "140px",
    // [theme.breakpoints.down("xs")]:{
    //   display:"none",
    // },
  },
  drawerContainer: {
    padding: "20px",
    boxSizing: "border-box",
    height: "100%",
    background: "#ffffff",
    color: "#262626",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& .MuiSelect-select ": {
      border: "none",
    },
  },
  profileIcon: {
    position: "absolute",
    right: "113px",
  },
  profileIcon1: {
    position: "absolute",
    right: "33px",
  },
  profileIcon2: {
    // position: "absolute",
    // right: "-20px",
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
    position: "absolute",
    right: "0",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
      width: "75px",
    },
  },
  menuMobile: {
    fontSize: "14px",
    fontWeight: "500",
    paddingLeft: "10px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    // justifyContent: "space-between",
    // padding: "0px",
    justifyContent: "end",
  },
  search: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#DAF4FF",
    border: "1px solid #fff",
    marginLeft: 20,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: "0px 9px",
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: "8px 6px 8px 0px",
    fontSize: "12px",
    marginTop: "-2px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
  },
  searcBox: {
    backgroundColor: "#DAF4FF",
    borderRadius: " 50px",
  },
  menuMobile1: {
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
      },
    },
  },
  menuMobile2: {
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: theme.palette.background.dark,
      margin: "0 5px",
      whiteSpace: "pre",
      fontWeight: "300",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#ff3965",
    },
  },
  menuMobiledrawer: {
    "& h4": {
      fontSize: "16px",
      lineHeight: " 17px",
      color: "#000",

      whiteSpace: "pre",
      fontWeight: "400",
    },
  },
  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 100px)",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      overflowY: "auto",
    },
  },
  selectHeader: {
    "& .MuiInputBase-input": {
      color: "#636880",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
  },

  mobileDraweSelect: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
    marginTop: "20px",
  },

  mobileMenuItem: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    fontSize: "16px",
    color: "#262626",
  },

  PopOverClass: {
    "& .MuiPopover-paper": {
      padding: "20px 0 18px 0 ",
      width: "100%",
      maxWidth: "220px",
      position: "absolute",
    },
  },
  profileMenuItem: {
    backgroundColor: "white",
    gap: "10px",
    alignItems: "center",
    padding: "0",
    "& .btnProfile": {
      whiteSpace: "pre",
      border: "none",
    },
    "& .btnProfile": {
      whiteSpace: "pre",
      border: "none",
      "& h5": {
        fontSize: "16px",
        color: "#262626",
        display: "flex",
        alignItems: "start",
      },
    },
  },
  profileAccount: {
    backgroundColor: "white",
    gap: "10px",
    alignItems: "center",
    marginTop: "10px",
    "& .myAccTypo": {
      fontSize: "16px",
      color: "#262626",
    },
  },
  suggestions: {
    color: "#262626",
    position: "absolute",
    backgroundColor: "#fff",
    top: "44px",
    borderRadius: "10px",
    background: "#fff",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    width: "100%",
    maxWidth: "400px",
    "& .suggestionslist": {
      cursor: "pointer",
      paddingLeft: "20px",
      "& li": {
        marginBottom: "6px",
      },
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  const history = useHistory();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [coeSelect, setCoeSelect] = useState("");
  const [marketplaceSelect, setMarketplaceSelect] = useState("");
  const [mobileExplore, setMobileExplore] = useState("");
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = useContext(AuthContext);
  const profileImage = auth?.profileImage;
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const handleLogoutClose = () => {
    setIsLogout(false);
  };

  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl2(null);
  };
  const profileData = auth?.profileData;

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const {
    divstake,
    toolbar,
    drawerContainer,
    drawericon,
    profileIcon,
    profileIcon1,
    profileIcon2,
    logoDrawer,
    mainHeader,
    mobileDraweSelect,
    mobileMenuItem,
  } = useStyles();
  const [selectedPage, setSelectedPage] = React.useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleChange = (event) => {
    setSelectedPage(event.target.value);
    const value = event.target.value;
    setQuery(value);
    fetchSuggestions(value);
  };

  const skillMarketPlaceRoute =
    history.location.pathname === "/skill-marketplace" ||
    history.location.pathname === "/skill-bridge" ||
    history.location.pathname === "/skill-drops";

  const handleSelectSuggestion = (suggestion, index) => {
    history.push({
      pathname: "/skill-marketplace",
      state: {
        categoryId: suggestion?._id,
        categoryName: suggestion?.categoryName,
        description: suggestion?.description,
        bannerImage: suggestion?.bannerImage,
        categoryImage: suggestion?.categoryImage,
        userId: index + 1,
      },
    });
    setSearchQuery(suggestion?.categoryName);
    setSuggestions([]);
  };

  const handlePush = () => {
    history.push("/skill-marketplace");
  };
  
  const chatBothandle = () => {
    const token = localStorage.getItem('token');
  
    // if (token) {
    //   history.push("/chat-bot");
    // } else {
    //   history.push("/login");
    // }
    history.push("/chat-bot");
  };

  const handlePushed = () => {
    history.push("/skill-drops");
  };
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const fetchSuggestions = async () => {
    try {
      const params = {};
      if (searchQuery) {
        params.name = searchQuery;
      }
      const res = await axios({
        method: "GET",
        url: apiConfig.getAllCategory,
        params: params,
      });
      if (res?.data?.responseCode === 200) {
        const filteredSuggestions = res?.data?.result?.docs.filter(
          (suggestion) =>
            suggestion.categoryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      }
    } catch (error) {
      if (error?.response) {
        // toast.error(error?.response?.data?.responseMessage);
      }
    }
  };

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box className="mainbox">
          <Header />
          {/* <FormControl fullWidth style={{ maxWidth: "400px" }}>
            <TextField
              variant="outlined"
              fullWidth
              className={skillMarketPlaceRoute}
              placeholder="Search"
              autoComplete="off"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                if (e.target.value.trim() === "") {
                  setSuggestions([]);
                } else {
                  fetchSuggestions();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CiSearch style={{ fontSize: "x-large" }} />
                  </InputAdornment>
                ),
              }}
            />

            {searchQuery &&
              suggestions.filter((suggestion) =>
                suggestion.categoryName
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              ).length > 0 && (
                <div className={classes.suggestions}>
                  <ul
                    className="suggestionslist"
                    style={{ listStyleType: "none" }}
                  >
                    {suggestions
                      .filter((suggestion) =>
                        suggestion.categoryName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleSelectSuggestion(suggestion, index)
                          }
                        >
                         {suggestion?.categoryName.slice(0, 30) + (suggestion.categoryName.length > 30 ? "..." : "")}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
          </FormControl> */}
        </Box>

        <div className="optionWrapper">
        <Button
            variant="body2"
            style={{
              fontWeight: 400,
              fontSize: "16px",
              width:'105px',
              border: "none",
              padding: " 0px 10px 1px 6px",
            }}
            onClick={chatBothandle}
          >
            Skills Gap
          </Button>
          <Button
            variant="body2"
            style={{
              fontWeight: 400,
              fontSize: "16px",
              width:"80px",
              border: "none",
              padding: " 0px 10px 1px 1px",
            }}
            onClick={handlePushed}
          >
            Skill-Drops
          </Button>
          {/* <Button
            variant="body2"
            style={{
              fontWeight: 400,
              fontSize: "16px",

              border: "none",
              padding: " 0px 10px 1px 1px",
            }}
            onClick={handlePush}
          >
            Marketplace
          </Button> */}
          <PopupState variant="popover" popupId="popup-popover2">
            {(popupState) => (
              <div>
                <div
                  className={
                    skillMarketPlaceRoute ? "marketPlaceOption" : "option"
                  }
                  {...bindTrigger(popupState)}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                  Marketplace
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div>

                    <p
                        onClick={handlePush}
                        style={{ cursor: "pointer" }}
                      >
                         <img src="/images/grid-01.svg" alt="" /> Skills Marketplace
                      </p>
                      
                   

                      <p
                        style={{ cursor: "pointer" }}
                        onClick={()=>{
                          history.push("/skill-bridge")
                        }}
                      >
                       <img src="/images/Skills Bridge.svg" alt="" /> Skills Bridge
                      </p>

                      <p
                        onClick={() => history.push("/all-categories")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="/images/dotpoints-02.svg" alt="" /> All Categories
                      </p>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </PopupState>


          <PopupState variant="popover" popupId="popup-popover1">
            {(popupState) => (
              <div>
                <div className="option" {...bindTrigger(popupState)}>
                  <span>
                    Explore
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div
                      style={{
                        borderRight: "1px solid #dcdcdc",
                        cursor: "pointer",
                      }}
                    >
                      <p onClick={() => history.push("/about-us")}>
                        <img src="/images/about_img.svg" alt="" /> About Us
                      </p>
                      <p
                        onClick={() =>
                          window.open("https://explorer.gyanbatua.com/")
                        }
                      >
                        <img src="/images/blockchain_img.svg" alt="" /> Gyan
                        chain
                      </p>

                      {/* <p onClick={() => history.push("/resource-center")}>
                        <img src="/images/resource_center_img.svg" alt="" />{" "}
                        Resource Center
                      </p> */}

                      <p
                        onClick={() =>
                          window.open(
                            "https://hyperledger-beta.mobiloitte.io/#/",
                            "_blank"
                          )
                        }
                      >
                        <img src="/images/catalouge_img.svg" alt="" /> Gyani KYC
                      </p>
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <p onClick={() => history.push("/faq")}>
                        <img src="/images/faq_img.svg" alt="" /> FAQs
                      </p>
                      <p onClick={() => history.push("/media")}>
                        <img src="/images/media_img.svg" alt="" /> Media
                      </p>

                      <p onClick={() => history.push("/contact-us")}>
                        <img
                          src="/images/iconcontact-us.png"
                          alt=""
                          style={{ height: "15px", width: "15px" }}
                        />{" "}
                        Contact Us
                      </p>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </PopupState>
          <PopupState variant="popover" popupId="popup-popover0">
            {(popupState) => (
              <div>
                <div className="option" {...bindTrigger(popupState)}>
                  <span style={{ whiteSpace: "nowrap" }}>
                    I & AI CoE
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div>
                      <p
                        onClick={() => history.push("/about-coe-program")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="/images/Aicoe2.png" alt="" />
                        About I & AI COEs
                      </p>
                      <p
                        onClick={() => history.push("/Existing-COE-Partners")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="/images/Aicoe2.png" alt="" /> Our existing
                        partners
                      </p>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </PopupState>

          {/* <PopupState variant="popover" popupId="popup-popover3">
            {(popupState) => (
              <div>
                <div className="option" {...bindTrigger(popupState)}>
                  <span>
                    Stats
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div>
                      <p
                        onClick={() => history.push("/ranking")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="/images/stats1.png" alt="" />
                        Ranking
                      </p>
                      <p
                        onClick={() => history.push("/activity")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="/images/stats2.png" alt="" />
                        Activity
                      </p>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </PopupState> */}

          <Box style={{ whiteSpace: "pre", height: "40px" }}>
            <Button
              variant="outlined"
              style={{
                whiteSpace: "pre",
                border: "none",
                background: "#C6C6C62B",
                padding: "8px 8px",
                height: "100%",
              }}
              onClick={() => {
                history.push("/cart");
              }}
            >
              {" "}
              <GrCart style={{ color: "grey" }} />
            </Button>
          </Box>

          <Box className={profileIcon2}>
            <Button
              variant="outlined"
              style={{
                whiteSpace: "pre",
                border: "none",
              }}
              onClick={handleClick}
            >
              {" "}
              {profileData?.firstName && (
                <>
                  <Typography style={{ color: "rgba(38, 38, 38, 1)" }}>
                    {profileData
                      ? profileData.firstName &&
                        profileData.firstName.length > 8
                        ? profileData.firstName.slice(0, 8) + "..."
                        : profileData.firstName || ""
                      : ""}
                  </Typography>
                  <ExpandMoreIcon />
                </>
              )}
              <Avatar
                src={profileData ? profileData?.profileImage : ""}
                style={{ color: "grey" }}
              />
            </Button>
          </Box>
        </div>
        <Popover
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={handleClose}
          className={classes.PopOverClass}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem className={classes.profileMenuItem}>
            <Button variant="outlined" className="btnProfile">
              <Avatar
                src={profileData ? profileData?.profileImage : ""}
                style={{ color: "grey" }}
              />
              <Box style={{ paddingLeft: "20px" }}>
                <Typography variant="h5">
                  {" "}
                  {profileData
                    ? `${
                        profileData.firstName &&
                        profileData.firstName.length > 5
                          ? profileData.firstName.slice(0, 5) + "..."
                          : profileData.firstName || ""
                      } ${
                        profileData.lastName && profileData.lastName.length > 5
                          ? profileData.lastName.slice(0, 5) + "..."
                          : profileData.lastName || ""
                      }`
                    : ""}
                </Typography>
                <Typography variant="body2">
                  {profileData && profileData.hashCode
                    ? `${profileData.hashCode.slice(
                        0,
                        7
                      )}..${profileData.hashCode.slice(-7)}`
                    : ""}
                </Typography>
              </Box>
            </Button>
          </MenuItem>
          <Divider />
          <MenuItem className={classes.profileAccount}>
            <PersonOutlineIcon style={{ color: "#000000" }} />
            <Typography
              onClick={() => {
                setOpen(true);
                handleClose();
                history.push("/profile");
              }}
              className="myAccTypo"
            >
              My Account
            </Typography>
          </MenuItem>
          <MenuItem
      
            className={classes.profileAccount}
          >
            <PowerSettingsNewIcon style={{ color: "#000000" }} />
            <Typography onClick={() => setIsLogout(true)} className="myAccTypo">
              Logout
            </Typography>
            {isLogout && (
              <CommonPopup
                type="logout"
                open={isLogout}
                onClose={handleLogoutClose}
                title="Logout"
                text="Are you sure you want to log out?"
              />
            )}
          </MenuItem>
        </Popover>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Box mb={1}>
              <Link to="/">
                <img className={logoDrawer} src="images/logo.svg" alt="" />
              </Link>
            </Box>

            <div className={mobileDraweSelect}>
            <Button
                variant="body2"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                }}
                onClick={chatBothandle}
              >
                Skills Gap
              </Button>

              <Button
                variant="body2"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                }}
                onClick={handlePushed}
              >
                Skill-Drops
              </Button>

              {/* <Button
                variant="body2"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                }}
                onClick={handlePush}
              >
                Marketplace 
              </Button> */}


<Select
                variant="outlined"
                displayEmpty
                value={marketplaceSelect}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                Marketplace 
                </MenuItem>

                <MenuItem
                  value="partnerWithUs"
                  className={mobileMenuItem}
                  onClick={() => history.push("/skill-marketplace")}
                >
                  <img src="/images/grid-01.svg" alt="" /> 
                  Skills Marketplace
                </MenuItem>

                <MenuItem
                  value="partnerWithUs"
                  className={mobileMenuItem}
                  onClick={() => history.push("/skill-bridge")}
                >
                  <img src="/images/Skills Bridge.svg" alt="" />
                  Skills Bridge
                </MenuItem>

                <MenuItem
                  value="existingPartner"
                  className={mobileMenuItem}
                  onClick={() => history.push("/all-categories")}
                >
                  <img src="/images/dotpoints-02.svg" alt="" /> All Categories
                </MenuItem>
              </Select>

              <Select
                variant="outlined"
                displayEmpty
                value={mobileExplore}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  Explore
                </MenuItem>
                <MenuItem
                  value="aboutUs"
                  onClick={() => history.push("/about-us")}
                  className={mobileMenuItem}
                >
                  <img src="/images/about_img.svg" />
                  About Us
                </MenuItem>
                <MenuItem
                  value="aboutUs"
                  onClick={() => window.open("https://explorer.gyanbatua.com/")}
                  className={mobileMenuItem}
                >
                  <img src="/images/blockchain_img.svg" />
                  Gyan Chain
                </MenuItem>
                <MenuItem
                  value="aboutUs"
                  onClick={() =>
                    window.open(
                      "https://hyperledger-beta.mobiloitte.io/#/",
                      "_blank"
                    )
                  }
                  className={mobileMenuItem}
                >
                  <img src="/images/catalouge_img.svg" alt="" />
                  Gyani KYC
                </MenuItem>
                <MenuItem
                  value="media"
                  onClick={() => history.push("/faq")}
                  className={mobileMenuItem}
                >
                  <img src="/images/faq_img.svg" alt="" />
                  FAQs
                </MenuItem>
                <MenuItem
                  value="media"
                  onClick={() => history.push("/media")}
                  className={mobileMenuItem}
                >
                  <img src="/images/media_img.svg" />
                  Media
                </MenuItem>
                <MenuItem
                  value="media"
                  onClick={() => history.push("/contact-us")}
                  className={mobileMenuItem}
                >
                  <img
                    src="/images/iconcontact-us.png"
                    style={{ height: "15px", width: "15px" }}
                  />
                  Contact Us
                </MenuItem>
              </Select>

              <Select
                variant="outlined"
                displayEmpty
                value={coeSelect}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  I & AI CoE
                </MenuItem>

                <MenuItem
                  value="partnerWithUs"
                  className={mobileMenuItem}
                  onClick={() => history.push("/about-coe-program")}
                >
                  <img src="/images/Aicoe2.png" alt="" />
                  About I & AI COEs
                </MenuItem>

                <MenuItem
                  value="existingPartner"
                  className={mobileMenuItem}
                  onClick={() => history.push("/Existing-COE-Partners")}
                >
                  <img src="/images/Aicoe2.png" alt="" /> Our existing partners
                </MenuItem>
              </Select>

              <Select
                variant="outlined"
                displayEmpty
                value={coeSelect}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  Stats
                </MenuItem>

                <MenuItem
                  value="partnerWithUs"
                  onClick={() => history.push("/ranking")}
                  className={mobileMenuItem}
                >
                  <img src="/images/stats1.png" alt="" />
                  Ranking
                </MenuItem>

                <MenuItem
                  value="existingPartner"
                  onClick={() => history.push("/activity")}
                  className={mobileMenuItem}
                >
                  <img src="/images/stats1.png" alt="" /> Activity
                </MenuItem>
              </Select>

              {/* <Button
                variant="containedPrimary"
                style={{
                  fontSize: "14px",

                  whiteSpace: "pre",
                  cursor: "pointer",
                  height: "40px",
                  padding: "9px 15px",
                  border: "1px solid transparent",
                }}
                onClick={() => history.push("/login")}
              >
                Get Started
              </Button> */}

              <Button
                variant="outlined"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                }}
                onClick={() => {
                  history.push("/cart");
                }}
              >
                {" "}
                <GrCart style={{ color: "grey" }} />
              </Button>
            </div>
          </div>
        </Drawer>
        <Box className="displayEnd">
          <Box display="flex" alignItems="center" justifyContent="end">
            <Box display="flex" alignItems="center" justifyContent="end">
              <Button
                variant="outlined"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "44px",
                }}
              >
                {" "}
                <IoNotificationsOutline style={{ color: "grey" }} />
              </Button>
              <Button
                variant="outlined"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                }}
                onClick={handleClick}
              >
                {" "}
                <Typography>
                  {profileData
                    ? profileData.firstName && profileData.firstName.length > 8
                      ? profileData.firstName.slice(0, 8) + "..."
                      : profileData.firstName || ""
                    : ""}
                </Typography>
                <ExpandMoreIcon />
                <Avatar
                  src={profileData ? profileData?.profileImage : ""}
                  style={{ color: "grey" }}
                />
              </Button>
            </Box>
            <Popover
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={handleClose}
              className={classes.PopOverClass}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem className={classes.profileMenuItem}>
                <Button variant="outlined" className="btnProfile">
                  <Avatar
                    src={profileData ? profileData?.profileImage : ""}
                    style={{ color: "grey" }}
                  />
                  <Box style={{ paddingLeft: "20px" }}>
                    <Typography variant="h5">
                      {" "}
                      {profileData
                        ? `${
                            profileData.firstName &&
                            profileData.firstName.length > 5
                              ? profileData.firstName.slice(0, 5) + "..."
                              : profileData.firstName || ""
                          } ${
                            profileData.lastName &&
                            profileData.lastName.length > 5
                              ? profileData.lastName.slice(0, 5) + "..."
                              : profileData.lastName || ""
                          }`
                        : ""}
                    </Typography>
                    <Typography variant="body2">
                      {profileData && profileData.hashCode
                        ? `${profileData.hashCode.slice(
                            0,
                            7
                          )}..${profileData.hashCode.slice(-7)}`
                        : ""}
                    </Typography>
                  </Box>
                </Button>
              </MenuItem>
              <Divider />
              <MenuItem className={classes.profileAccount}>
                <PersonOutlineIcon />
                <Typography
                  onClick={() => {
                    setOpen(true);
                    handleClose();
                    history.push("/profile");
                  }}
                  className="myAccTypo"
                >
                  My Account
                </Typography>
              </MenuItem>
              <MenuItem className={classes.profileAccount}>
                <PowerSettingsNewIcon />
                <Typography
                  onClick={() => setIsLogout(true)}
                  className="myAccTypo"
                >
                  Logout
                </Typography>
                {isLogout && (
                  <CommonPopup
                    type="logout"
                    open={isLogout}
                    onClose={handleLogoutClose}
                    title="Logout"
                    text="Are you sure you want to log out?"
                  />
                )}
              </MenuItem>
            </Popover>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{
                  color: "#EC1F24",
                  fontSize: "26px",
                  position: "absolute",
                  right: "0",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    );
  };

  //********************************************* */

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            // style={{ padding: "0px" }}
          >
            <SvgIcon>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          &nbsp; &nbsp;
        </Hidden>
        <Box className={classes.mainheader}>
          <Container maxWidth="xlg">
            <Grid container alignItems="center">
              <Grid item lg={2} md={3} sm={3} xs={3}>
                <Box className="leftBox">
                  <Link to="/">
                    {isSmallScreen ? null : (
                      <img src="/images/logo.svg" alt="Logo" />
                    )}
                  </Link>
                </Box>
              </Grid>

              {/* <Hidden smDown> */}
              <Grid item lg={10} md={9} sm={9} xs={9}>
                {mobileView ? displayMobile() : displayDesktop()}
              </Grid>
              {/* </Hidden> */}
            </Grid>
          </Container>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

// TopBar.propTypes = {
//   className: PropTypes.string,
// };
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

// export function TopBarData() {
//   const classes = useStyles();
//   const history = useHistory();
//   const auth = useContext(AuthContext);

//   return (
//     <>
//       <Box display="flex" alignItems="center" justifyContent="flex-end">
//         <Hidden xsDown>
//           <Box>
//             <Typography variant="h5">NFT Marketplace</Typography>
//             <Typography variant="body1" style={{ color: "#ffffff9c" }}>
//               example@gmail.com
//             </Typography>
//           </Box>
//         </Hidden>

//         &nbsp; &nbsp;
//         <Avatar
//           src={
//             auth?.userData?.profilePic
//               ? `${auth?.userData?.profilePic}`
//               : "https://picsum.photos/533/357"
//           }
//           className={classes.avatar}
//           // onClick={() => history.push("/admin-profile")}
//         />
//       </Box>
//     </>
//   );
// }
